import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import validator from 'validator';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import './index.scss';
import ToggleInput from '../../componentParts/ToggleInput';
import { customBaseUrl, httpPost, httpGet, httpPosPost } from '../../services/http';
import Button from '../../componentParts/Button';
import TermsAndCondition from '../../componentParts/Modals/Terms/TermsAndCondition';
import PrivacyPolicy from '../../componentParts/Modals/Terms/PrivacyPolicy';
import Bg from '../../assets/images/BG.png';
import MerchantForm from '../../componentParts/Modals/MerchantForm';
import MerchantForm2 from '../../componentParts/Modals/MerchantForm2';
import MerchantForm3 from '../../componentParts/Modals/MerchantForm3';
import VerifyOtp from '../../componentParts/Modals/Otp/VerifyOtp';
import ResendOtp from '../../componentParts/Modals/Otp/ResendOtpOutside';
import { getBusinessTypes } from '../../services/calls';
import Logo from '../../assets/newlog.svg';
import quickLogo from "../../assets/wayaquick.png";
import posLogo from "../../assets/wayaPos.png";
import gramLogo from "../../assets/wayagram.png";
import { NotificationManager, Notifications } from 'react-notifications';
import { hideLoader, showLoader } from '../../utils/loader';
import moment from 'moment';
import CacForm from '../../componentParts/Modals/CacForm';
import wayabankLogo from "../../assets/newlog.png";
import CACForm2 from '../../componentParts/Modals/CACForm2';
import { Modal } from 'reactstrap';
import { Skeleton } from 'antd';
import { ArrowBack } from '@mui/icons-material';

const initialData = {
  firstName: '',
  surname: '',
  email: '',
  password: '',
  phoneNumber: '234',
  confirmPassword: '',
  supportCode: null,
  orgName: '',
  // orgPhone: '234',
  // cacRegNo: null
}
const SignupPage = (props) => {
  const history = useHistory();
  const [businessTypes, setBusinessTypes] = useState([]);
  const [mode, setMode] = useState(null);
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [data, setData] = useState(initialData);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [page, setPage] = useState(1);
  const [dateError, setDateError] = useState(null);
  const [showClientModal, setShowClientModal] = useState(false);
  const [client, setClient] = useState(null);

  // const myRef = React.useRef(null);
  // const CLIENT_TYPE = localStorage.getItem('CLIENT_TYPE');

  const getBusinessList = async () => {
    const res = await getBusinessTypes();
    setBusinessTypes(res);
  };

  const handleSubmit = async () => {

    const term = $('#term1').is(':checked');
    if (!term) {
      swal('Oops!', 'Please Agree to Terms & conditions', 'error');
      setLoading(false);
      return;
    }

    if (validator.isEmpty(data.firstName)) {
      swal('Oops!', 'First name cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.surname)) {
      swal('Oops!', 'Surname cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.email)) {
      swal('Oops!', 'Email cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (!validator.isEmail(data.email)) {
      swal('Oops!', 'Invalid Email format', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(data.password)) {
      swal('Oops!', 'Password cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (dateError === 'Invalid date') {
      swal('Oops!', 'invalid date of birth', 'error');
      setLoading(false);
      return;
    }

    const PASSWORD_REGEX = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'; // _ added
    if (!data.password.match(PASSWORD_REGEX)) {
      swal(
        'Oops!',
        'Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters',
        'error'
      );
      setLoading(false);
      return;
    }

    if (validator.isEmpty(data.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    /*const formattedPhone = `${parsePhoneNumber(data.phoneNumber).countryCallingCode
      }${parsePhoneNumber(data.phoneNumber).nationalNumber}`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }*/

    if (data.phoneNumber.length != 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    }

    if (!validator.equals(data.password, data.confirmPassword)) {
      swal('Oops!', 'Password do not match', 'error');
      setLoading(false);
      return;
    }

    const postData = {
      admin: false,
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      surname: data.surname,
      phoneNumber: data?.phoneNumber,
      referenceCode: data.referenceCode,
      supportCode: data.supportCode,
      dateOfBirth: data.dateOfBirth

    };

   
    const hiddenPhone = `*********${data.phoneNumber.substr(-4, 4)}`;
    setLoading(true);
    const res = await httpPost(
      '/api/v1/auth/create',
      postData,
      customBaseUrl.authUrl,
      mode !== 'individual' ? 'CORPORATE' : 'PERSONAL',
      true
    );
    // console.log(res);
    if (res.status) {
      setPhone(data?.phoneNumber);
      setLoading(false);
      // resendOtp(data.phoneNumber);
      swal(
        'Done',
        `Account created! and a verification OTP has been sent to ${hiddenPhone}`,
        'success',
        {
          button: {
            text: 'Continue to OTP',
            className: 'button-success',
            value: true,
          },
          closeOnClickOutside: false,
        }
      ).then(() => {
        if (mode !== 'individual') {
          setShowClientModal(true);
        } else {
          setShowVerifyOtp(true);
        }
        swal.close();
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const resendOtp = async (phoneNumber, bid) => {
    setLoading(true);
    setPhone(phoneNumber);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${phoneNumber}${mode !== 'individual' ? '?businessId='+bid : ''}`,
      mode !== 'individual' ? 'CORPORATE' : 'PERSONAL'
    );
    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success').then(() => {
        setShowResendOtp(false);
        if (mode !== 'individual') {
          setShowClientModal(true);
        } else {
          setShowVerifyOtp(true);
        }
      });
    } else {
      setLoading(false);
      swal('Wrong', `${res.message}`, 'error', {
        button: {
          text: 'Resend OTP',
          className: 'button-warning',
        },
      }).then(() => {
        setPhone('');
      });
    }
  };

  const completeSignup = async () => {
    setLoading(true);
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
      businessId: mode !== 'individual' ? data?.loginId : ''
    };
    const res = await httpPost(
      '/api/v1/auth/verify-otp',
      postData,
      customBaseUrl.authUrl,
      mode !== 'individual' ? 'CORPORATE' : 'PERSONAL',
      true,
      mode !== 'individual' ? data?.loginId : ''
    );
    if (res.status) {
      setLoading(false);
      setOtp('');
      setPhone('');
      setShowVerifyOtp(false);
      swal('Done', res.message, 'success').then(() => {
        history.push(`/login?client=${client}`);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  const handleSignup = async () => {
    if (!validator.equals(data.password, data.confirmPassword)) {
      swal('Oops!', 'Password do not match', 'error');
      setLoading(false);
      hideLoader();
      return;
    }

    /*const formattedPhone = `${parsePhoneNumber(data.phoneNumber).countryCallingCode
      }${parsePhoneNumber(data.phoneNumber).nationalNumber}`;

    const formattedOrgPhone = `${parsePhoneNumber(data.orgPhone).countryCallingCode
      }${parsePhoneNumber(data.orgPhone).nationalNumber}`;
      */
      if (data?.phoneNumber?.length != 13) {
        swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
        setLoading(false);
        return;
      }
      // if (data?.orgPhone?.length != 13) {
      //   swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      //   setLoading(false);
      //   return;
      // }
      if (dateError === 'Invalid date') {
        swal('Oops!', 'invalid date of birth', 'error');
        setLoading(false);
        return;
      }
      showLoader();
    const postData = {
      accessType: null,
      admin: false,
      corporate: true,
      businessType: true,
      cacAccount: mode === 'cac' ?? false,
      ...data,
      orgPhone: data?.phoneNumber,
      phoneNumber: data?.phoneNumber,
      orgEmail: data.email,
      userId: 0,
    };

    const hiddenPhone = `*********${data.phoneNumber.substr(-4, 4)}`;
    setLoading(true);
    const res = await httpPost(
      '/api/v1/auth/create-corporate',
      postData,
      customBaseUrl.authUrl,
      mode !== 'individual' ? 'CORPORATE' : 'PERSONAL',
      true
    );

    // const res1 = await httpPosPost(
    //   '/api/v1/auth/merchant/registration',
    //   postData,
    //   customBaseUrl.posAgencyUrl,
    //   true
    // );
   
    // if(res1?.respDesc){
    //   hideLoader();
    // }
    // console.log(res);
    if (res.status) {
      setPhone(data.phoneNumber);
      setLoading(false);
      hideLoader();
      swal(
        'Done',
        `Account created! and a verification OTP has been sent to ${hiddenPhone}`,
        'success',
        {
          button: {
            text: 'Continue to OTP',
            className: 'button-success',
            value: true,
          },
          closeOnClickOutside: false,
        }
      ).then(() => {
        if (mode !== 'individual') {
          setShowClientModal(true);
        } else {
          setShowVerifyOtp(true);
        }
        swal.close();
      });
    } else {
      setLoading(false);
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const handleClientId = () => {
    const phoneValidation = /^([\s()-]*\d[\s()-]*){12}/;
    if (!data?.loginId) return swal('Oops!', 'Business Id can not be null');
    if (!data?.loginId.match(phoneValidation))
      return swal('Oops!', 'Input a valid Business ID');
    setShowVerifyOtp(true);
    setShowClientModal(false);
  }
  useEffect(() => {
    getBusinessList();
    return () => {
      setBusinessTypes([]);
    };
  }, []);

  useEffect(() => {
    if (!mode) return
    Swal.fire({
      icon: 'question',
      text: `If You have an existing account kindly log in with your credentials to add more accounts`,
      // confirmButtonText: 'Yes, proceed',
      //  showCancelButton: true,
    })
    //.then((result) => {
    //   if (result.isConfirmed) {
    //     httpPost(`/contests/vote`, postData, customBaseUrl.contestUrl).then(
    //       (res) => {
    //         swal(res?.message);
    //         handleContestData();
    //       }
    //     );
    //   }
    // });
  }, [mode])

  useEffect(() => {
    const { location } = history;
    const {
      location: { search },
    } = props;
    const clientId= new URLSearchParams(search).get("client");
    const rCode= new URLSearchParams(search).get("isReferral");
    setClient(clientId ?? 'wayabank');
    setData({ ...data, referenceCode: rCode ?? '' });
    if (clientId === 'wayapos' || clientId === 'wayaquick') {
      localStorage.setItem('clientType', 'CORPORATE');
      localStorage.setItem('clientId', clientId ? clientId.toUpperCase() : 'WAYABANK');
      setMode('corporate');
    }
    if (new URLSearchParams(location.search).get('tab') === 'corporate') {
      setMode('corporate');
    }
  }, []);
  
  const handleDateChange = (e, value) => {
    const selected = new Date(data?.dateOfBirth);
    const maxDate = new Date();
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate());
    console.log(selected - maxDate);
    if (selected - maxDate < 0) {
      setDateError(null);
      return true;
    } else {
      setDateError("Invalid date");
      return false;
    }
  };
  return (
    <div
      id='login-bg'
      style={{
        backgroundImage: `url(${Bg})`,
      }}
    >
      <div id='login-modal' className='modal-body-rs-log col-12'>
      <Modal isOpen={showClientModal} toggle={() => setShowClientModal(false)} centered>
        <div className='modal-body-rs p-2 pb-3'>
          <div className='waya-modal-body'>
            <div className='inputbox-with-one-input-log mt-5'>
              <div className='mb-5'>
                Please input the Business Id sent to your email address or phone number
              </div>
              <input
                placeholder='Business ID'
                type='text'
                value={data?.loginId}
                onChange={(e) => {
                  setData({ ...data, loginId: e.target.value });
                }}
              />
            </div>
            <div className='submit-modal-btn-wrap-log'>
              <Button
                type='button'
                loading={loading}
                disabled={loading && false}
                onClick={handleClientId}
                content='Continue'
              />
            </div>
          </div>
        </div>
      </Modal>
        <div className='waya-modal-body-log mb-5 text-center' style={{ display: 'flex', flexDirection:'column'}}>
            {mode ? <ArrowBack style={{ marginLeft: 0}} onClick={() => setMode('') }/>: ''}
          {!mode && client === 'wayabank' ? (
            <div>
              <h5 className='modal-header-data-log'>
                Select Account Type
              </h5> 
              <div
                className='w-auto text-center mx-auto my-auto'
                style={{ border: '1px solid #b6b3b3', marginTop: 20, height: 40 }}
                role='button'
                tabIndex={0}
                aria-hidden='true'
                onClick={() => {
                  setData(initialData)
                  localStorage.setItem('clientType', 'PERSONAL');
                  setDateError('')
                  setMode('individual')
                  setPage(1);
                }}
              >
                Personal Account
              </div>
              <div
                className='w-auto text-center mx-auto my-10 py-auto'
                style={{ border: '1px solid #b6b3b3', marginTop: 20, height: 40 }}
                role='button'
                tabIndex={0}
                aria-hidden='true'
                onClick={() => {
                  setData(initialData)
                  localStorage.setItem('clientType', 'CORPORATE');
                  setDateError('')
                  setMode('corporate');
                  setPage(1);
                }}
              >
                Business Account
              </div>
            </div>
          ) : (
            <div>    
              {!client ? (
                <Skeleton width='70%' height={60} />
                ) : (
                <img
                  className="header-sec-logo-log mx-auto"
                  style={{ width: '70%', height: 60 }}
                  src={client === 'wayabank' 
                    ? Logo
                    : client === 'wayaquick' 
                    ? quickLogo
                    : client === 'wayapos' 
                    ? posLogo
                    : client === 'wayagram' 
                    ? gramLogo
                    : '' }
                  alt=""
                />
                )}
                <h6 className="navbar-brand ml-4 pl-3 text-black nav-link fst-italic">
                  Wire your money, your way
                </h6>
              <div
                className='w-auto text-center mx-auto'
                style={{ border: '1px solid #b6b3b3', marginTop: 20 }}
              >
                  <div className='row m-0'>
                  {client === 'wayabank' || client === 'wayagram' ? (
                  // <div
                  //   role='button'
                  //   tabIndex={0}
                  //   aria-hidden='true'
                  //   className={mode === 'individual' ? 'col reg-active' : 'col'}
                  //   onClick={() => {
                  //     setData(initialData)
                  //     localStorage.setItem('clientType', 'PERSONAL');
                  //     setDateError('')
                  //     setMode('individual')
                  //     setPage(1);
                  //   }}
                  // >
                  //   Personal
                  // </div>
                  <div />
                  ) : (
                    ''
                  )}
                  {mode === 'corporate'|| mode === 'cac' ? (
                    <>
                    <div
                      role='button'
                      tabIndex={0}
                      aria-hidden='true'
                      className={mode === 'corporate' ? 'col reg-active' : 'col'}
                      onClick={() => {
                        setData(initialData)
                        localStorage.setItem('clientType', 'CORPORATE');
                        setDateError('')
                        setMode('corporate');
                        setPage(1);
                      }}
                    >
                      Non Registered Business
                    </div>
                    <div
                      role='button'
                      tabIndex={0}
                      aria-hidden='true'
                      className={mode === 'cac' ? 'col reg-active' : 'col'}
                      onClick={() => {
                        setData(initialData)
                        setMode('cac');
                        localStorage.setItem('clientType', 'CORPORATE');
                        setPage(1);
                      }}
                    >
                      CAC Registered Business
                    </div>
                    </>
                  ) : (
                    ''
                  )}
                  </div>
                
              </div>
              {mode === 'individual' && (
                <>
                  <h1 className='modal-header-data-log'>
                    Create your personal account
                  </h1>              
                  <form>
                    <div className='inputbox-with-one-input-log'>
                      <input
                        placeholder='First name'
                        type='text'
                        onChange={(e) => {
                          setData({ ...data, firstName: e.target.value });
                        }}
                      />
                    </div>

                    <div className='inputbox-with-one-input-log'>
                      <input
                        placeholder='Surname'
                        type='text'
                        onChange={(e) => {
                          setData({ ...data, surname: e.target.value });
                        }}
                      />
                    </div>

                    <div className='inputbox-with-one-input-log'>
                      <input
                        placeholder='Email Address'
                        type='text'
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>

                    <div className='inputbox-with-one-input-log'>
                      {/* <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">DOB</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Date of birth"
                          type="date"
                          value={data.dateOfBirth}
                          onMouseOut={handleDateChange}
                          onChange={(e) => {
                            setData({ ...data, dateOfBirth: moment(e.target.value).format('YYYY-MM-DD') });
                          }}
                        />
                      </div> */}
                      <span className="text-danger my-0">{dateError ?? ''}</span>
                      {/*<PhoneInput
                        placeholder='Phone Number'
                        defaultCountry='NG'
                        international
                        value={data.phoneNumber}
                        limitMaxLength='13'
                        onChange={(e) => {
                          setData({ ...data, phoneNumber: e })
                      }
                        }
                      />*/}
                      </div>
                      <div className='inputbox-with-one-input-log'>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">Tel</span>
                        </div>
                      <input
                        value={data?.phoneNumber}
                        className="form-control"
                        length='13'
                        maxLength={13}
                        onChange={(e)=>{                      
                          const onlyDigits = e.target.value.replace(/\D/g, "");
                          setData({ ...data, phoneNumber: onlyDigits })
                        }}  />
                    </div>
                    </div>

                    <div className='inputbox-with-one-input-log'>
                      <input
                        placeholder='Referral Code (optional)'
                        type='text'
                        value={data.referenceCode}
                        onChange={(e) => {
                          setData({ ...data, referenceCode: e.target.value });
                        }}
                      />
                      <label style={{ fontSize: 8, textAlign: 'start' }}>
                        Referral code
                      </label>
                    </div>
                    <div className='inputbox-with-one-input-log'>
                      <input
                        placeholder='Support Code (optional)'
                        type='text'
                        onChange={(e) => {
                          setData({ ...data, supportCode: e.target.value });
                        }}
                      />
                    </div>

                    <div className='inputbox-with-one-input-log toggle-pass-wrapper'>
                      <ToggleInput
                        placeholder='Password'
                        type='password'
                        onChange={(e) => {
                          setData({ ...data, password: e.target.value });
                        }}
                      />
                    </div>

                    <div className='inputbox-with-one-input-log toggle-pass-wrapper'>
                      <ToggleInput
                        placeholder='Confirm Password'
                        type='password'
                        onChange={(e) => {
                          setData({ ...data, confirmPassword: e.target.value });
                        }}
                      />
                    </div>

                    <div className='form-group'>
                      <input type='checkbox' id='term1' />{' '}
                      <span className='add-cursor'>
                        By signing up, you agree with the{' '}
                        <span
                          className='primary-link'
                          onClick={() => {
                            // setShowTerms(true);
                            history.push('/terms')
                          }}
                        >
                          terms and conditions
                        </span>{' '}
                        and{' '}
                        <span
                          className='primary-link'
                          onClick={() => {
                            // setShowPrivacy(true);
                            history.push('/privacy-policy')
                          }}
                        >
                          privacy policy
                        </span>
                      </span>
                    </div>
                  </form>
                  <div className='submit-modal-btn-wrap-log'>
                    <Button
                      type='button'
                      loading={loading}
                      disabled={loading && false}
                      onClick={handleSubmit}
                      content='Create account'
                    />
                  </div>
                </>
              )}
              {mode === 'corporate' || mode === 'cac' ? (
                <>
                  <h1 className='modal-header-data'>
                    Create your {mode === 'cac' ? '' : 'Non'} Registered Business Account
                  </h1>             
                  {page === 1 ? (
                    <MerchantForm
                      setPage={setPage}
                      setData={setData}
                      data={data}
                      businessTypes={businessTypes || []}
                      setLoading={setLoading}
                      loading={loading}
                      handleSignup={handleSignup}

                    />
                  ) : page === 2 ? (
                    <MerchantForm2
                      setPage={setPage}
                      setData={setData}
                      data={data}
                      dateError={dateError}
                      handleDateChange={handleDateChange}
                    />
                  ) : (
                    <MerchantForm3
                      setData={setData}
                      data={data}
                      setLoading={setLoading}
                      loading={loading}
                      setPage={setPage}
                      handleSignup={handleSignup}
                    />
                  )}              
                </>
              ) : (
                ''
              )}
              {/* {mode === 'cac' && (
                <>
                  <h1 className='modal-header-data'>
                    Create your CAC Registered Business Account
                  </h1>             
                  {page === 1 ? (
                    <CacForm
                      setPage={setPage}
                      setData={setData}
                      data={data}
                      businessTypes={businessTypes || []}
                    />
                  ) : page === 2 ? (
                    <MerchantForm2
                      setPage={setPage}
                      setData={setData}
                      data={data}
                      dateError={dateError}
                      handleDateChange={handleDateChange}
                    />
                  ) : page === '2-cac' ? (
                    <CACForm2
                      setPage={setPage}
                      setData={setData}
                      data={data}
                      dateError={dateError}
                      handleDateChange={handleDateChange}
                    />
                  ) : (
                    <MerchantForm3
                      setData={setData}
                      data={data}
                      setLoading={setLoading}
                      loading={loading}
                      setPage={setPage}
                      handleSignup={handleSignup}
                    />
                  )}              
                </>
              )} */}

              <div className='text-center'>
                <span className=''>
                  Have an account?{' '}
                  <Link className='text-link' to={`login?client=${client}`}>
                    Sign In{' '}
                  </Link>
                </span>
              </div>
              
              {/* <div className='text-center'>
                <span className=''>
                <a className="text-danger"
                    href="https://staging.wayabank.ng/signup"
                  >
                    Click here
                  </a>
                    {' '}to register on Sandbox environment for API Integration and carrying out test transactions.
                </span>
              </div> */}
              </div>
          )}
        </div>
      </div>
      {showTerms ? (
        <TermsAndCondition showModal={showTerms} hideModal={setShowTerms} />
      ) : (
        ''
      )}
      {showPrivacy ? (
        <PrivacyPolicy showModal={showPrivacy} hideModal={setShowPrivacy} />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=''
          title='Verify Your Account'
          description='Please input the OTP sent to your email address or phone number'
          isResendOtp
          buttonLabel='Verify'
          handleSubmit={completeSignup}
          loading={loading}
          numInputs={6}
          resendOtp={setShowResendOtp}
        />
      ) : (
        ''
      )}
      {showResendOtp ? (
        <ResendOtp
          center='true'
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title='Resend OTP'
          description='Please input the email and mobile number'
          handleSubmit={resendOtp}
          loading={loading}
          type={mode !== 'individual' ? 'corporate' : 'Individual'}
        />
      ) : (
        ''
      )}
    </div>
  );
};

SignupPage.defaultProps = {};

SignupPage.propTypes = {};

export default SignupPage;
